import React from 'react';
import '../styles/ContactModal.css';
import Button from './Button';
import Modal from 'react-modal';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BarLoader } from 'react-spinners';

Modal.setAppElement('#root');

const styles = theme => ({
  root: {
    margin: 0
  },
  label: {
    fontSize: 14
  },
  input: {
    fontSize: 14
  }
});

const INITIAL_STATE = {
  name: '',
  email: '',
  company: '',
  phone: '',
  amount: '',
  message: '',
  error: '',
  loading: false
};

class ContactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  componentDidMount() {
    this.setState({ ...INITIAL_STATE });
  }

  formSubmit = () => {
    if (!this.validateFields()) return;
    this.setState({ error: '', loading: true });
    axios
      .post('/api/contact-us', {
        name: this.state.name,
        name: this.state.phone,
        name: this.state.company,
        email: this.state.email,
        amount: this.state.amount,
        message: this.state.message
      })
      .then(response => {
        setTimeout(() => {
          this.onRequestClose();
          this.setState({ ...INITIAL_STATE });
          toast(
            'Thank you for contacting Cherokee. The team will reply to your message shortly!',
            {
              className: 'cherokee-toast'
            }
          );
        }, 3000);
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      })
      .finally(function() {
        // always executed
      });
  };

  validateFields = () => {
    if (!this.state.name) {
      this.setError('Name is required');
      return false;
    }
    if (!this.state.company) {
      this.setError('Company is required');
      return false;
    }
    if (!this.state.phone) {
      this.setError('Phone is required');
      return false;
    }
    if (!this.state.email) {
      this.setError('Email is required');
      return false;
    }
    if (!this.validateEmail(this.state.email)) {
      this.setError('Please enter a valid email address');
      return false;
    }
    if (!this.state.message) {
      this.setError('Message is required');
      return false;
    }
    return true;
  };

  validateEmail = email => {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
  };

  setError = msg => {
    this.setState({ error: msg });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onRequestClose = () => {
    this.setState({ ...INITIAL_STATE });
    this.props.onRequestClose();
  };

  render() {
    let customStyles = {
      content: {
        position: 'fixed',
        maxWidth: '500px',
        width: '80%',
        height: '650px',
        backgroundColor: '#ffffff',
        overflow: 'auto',
        outline: 'none',
        padding: '30px 20px',
        border: 'none',
        borderRadius: '0',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-around'
      },
      overlay: {
        backgroundColor: 'rgba(70, 70, 70, 0.8)',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center'
      }
    };
    const { classes } = this.props;
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        style={customStyles}
        className="header-modal"
      >
        <form id="contact-form">
          <h1>
            <span>C</span>ONTACT <span>U</span>S
          </h1>
          {this.state.error && (
            <p style={{ color: 'red' }}>{this.state.error}</p>
          )}
          <TextField
            label="Name"
            classes={{
              root: classes.root
            }}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
            InputProps={{
              classes: {
                root: classes.input
              }
            }}
            id="custom-css-outlined-input"
            type="text"
            margin="normal"
            variant="outlined"
            fullWidth
            name="name"
            value={this.state.name}
            onChange={this.onChange}
          />
          <TextField
            label="Email"
            type="email"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            classes={{
              root: classes.root
            }}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
            InputProps={{
              classes: {
                root: classes.input
              }
            }}
            id="custom-css-outlined-input"
            fullWidth
            name="email"
            value={this.state.email}
            onChange={this.onChange}
          />
    <TextField
            label="Company"
            classes={{
              root: classes.root
            }}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
            InputProps={{
              classes: {
                root: classes.input
              }
            }}
            id="custom-css-outlined-input"
            type="text"
            margin="normal"
            variant="outlined"
            fullWidth
            name="company"
            value={this.state.company}
            onChange={this.onChange}
          />
        <TextField
            label="Phone Number"
            classes={{
              root: classes.root
            }}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
            InputProps={{
              classes: {
                root: classes.input
              }
            }}
            id="custom-css-outlined-input"
            type="text"
            margin="normal"
            variant="outlined"
            fullWidth
            name="phone"
            value={this.state.phone}
            onChange={this.onChange}
          />
          <TextField
            label="Claim Amount"
            classes={{
              root: classes.root
            }}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
            InputProps={{
              classes: {
                root: classes.input
              }
            }}
            id="custom-css-outlined-input"
            type="text"
            margin="normal"
            variant="outlined"
            fullWidth
            name="amount"
            value={this.state.amount}
            onChange={this.onChange}
          />
          <TextField
            classes={{
              root: classes.root
            }}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
            InputProps={{
              classes: {
                root: classes.input
              }
            }}
            label="Message"
            className={classes.textField}
            type="text"
            name="message"
            margin="normal"
            variant="outlined"
            multiline
            rows="6"
            fullWidth
            name="message"
            value={this.state.message}
            onChange={this.onChange}
          />
          <BarLoader
            sizeUnit={'px'}
            width={240}
            height={4}
            color={'#000078'}
            loading={this.state.loading}
          />
          <Button title="Send" onButtonClick={this.formSubmit} />
        </form>
      </Modal>
    );
  }
}

export default withStyles(styles)(ContactModal);
